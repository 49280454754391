.upgrade-paragraph {
  cursor: pointer;
  display: inline-block;
  &:hover {
    color: blueviolet;
    text-decoration: underline;
  }
}

a {
  text-decoration: none;
}
