.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip {
  position: absolute;
  width: 220px;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px;
  background-color: black;
  color: white;
  border-radius: 4px;
  z-index: 1;
  white-space: wrapping;
  opacity: 0.99;
  pointer-events: none;
  margin-bottom: 18px;
}

.triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid black;
  bottom: 100%;
  left: calc(50% - 20px);
  opacity: 0.99;
  transform: translateX(-50%);
  z-index: 1;
  margin-bottom: 8px;
  pointer-events: none;
}
