@import "../../common/variables";

.invited-registration {
  background: $light-beige-bg;
  padding: 30px 60px;
  min-height: 100vh;
  @media (max-width: 768px) {
    padding: 30px 15px;
  }
}

.invited-registration-form {
  position: relative;
  top: -35px;
  @media (max-width: 768px) {
    top: 0px;
  }

  &__title {
    font-family: $font-family-speziaserif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 34px;
    letter-spacing: -0.045em;
    color: #222222;
    @media (max-width: 768px) {
      font-size: 32px;
      line-height: 28px;
      letter-spacing: -0.035em;
    }
  }
  &__subtitle {
    font-weight: 300;
    font-size: 24px;
    line-height: 72px;
    letter-spacing: 0.02em;
    color: #000000;
    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 40px;
      letter-spacing: 0.01em;
      padding-top: 20px;
    }
  }
  &__branding-image {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 100;
  }

  .form-subcopy {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #0b0f1b;
  }

  .btn-primary {
    width: 181px;
    text-align: center;
  }

  .centered-button-row {
    .btn-primary {
      background-color: $light-green;
      margin: 0 auto;
    }
  }

  .agreement-text {
    color: black;
  }

  .agreement-text-link {
    color: $light-green;
    cursor: pointer;
  }

  #formExp {
    font-size: 12px;
    line-height: 24px;
  }
}
