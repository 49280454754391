@import "../../common/variables";

.header {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 84px;
  right: 0;
  height: 84px;
  background: white;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 100;

  svg {
    height: 34px;
    width: 35px;
    margin-right: 12px;
  }

  .header-menu-icon {
    height: 25px;
    width: 24px;
    margin-right: 20px;
  }

  .header-left {
    position: absolute;
    left: 20px;
  }

  .header-right {
    display: flex;
    position: absolute;
    right: 10px;
  }

  .avatar {
    color: #fff;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 42px;
    height: 42px;
    font-weight: 600;
    background-color: brown;
    font-size: 18px;
    font-family: $font-family-suisse;
  }

  .notification-badge {
    position: absolute;
    padding: 1px 9px;
    border-radius: 100%;
    background: #f55519;
    color: white;
    top: -10px;
    right: 25px;
    font-weight: 700;
    display: flex;
  }

  .icons-wrapper {
    position: relative;
    height: 42px;
    width: 42px;
    border-radius: 50%;
    position: relative;
    display: inline-block;
  }

  p {
    word-wrap: break-word;
    display: inline-block;
  }
}

button {
  border: none;
  background: none;
  cursor: pointer;
  color: #00AFAA;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
}

p {
  margin: 0;
}

#nav-sidebar {
  height: 100%;
  position: fixed;
  left: 0;
  width: 220px;
  margin-left: -220px;
  transition: all 250ms ease-in-out;
}

#nav-sidebar.right {
  margin-left: 0;
  transition: all 250ms ease-in-out;
}
