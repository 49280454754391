@import "../../common/variables";

.invitations {
  height: 100vh;
  background: white;
  padding: 8px 20px;

  @media (max-width: 768px) {
    padding: 0px;
  }

  .cases-icon {
    height: 25px;
    width: 27px;
    margin-right: 5px;
  }

  .none-sent {
    font-size: 24px;
    margin-top: 100px;
    font-weight: 700;
    font-family: $font-family-suisse;
    color: #f55519;
  }

  &__table {
    width: 100%;
    th:first-child {
      width: 20%;
    }

    .join-button {
      font-weight: 700;
      font-family: $font-family-suisse;
      font-size: 16px;
      letter-spacing: 0.02em;
    }

    .briefcase-icon {
      width: 27px;
      height: 25px;
    }

    thead {
      th,
      td {
        padding: 10px;
      }
      tr {
        padding: 10px;
        background-color: $light-beige-bg;
      }
    }

    tbody {
      tr {
        background-color: white;

        .avatar-cell {
          display: flex;
          justify-content: flex-start;
          margin-left: -38px;
          align-items: center;
        }
        td {
          border-bottom: 1px solid $light-beige-bg;
          font-weight: 600;
          &:nth-child(3) {
            position: relative;
            .avatar {
              color: #fff;
              padding: 2px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 100%;
              margin-right: -20px;
              width: 42px;
              height: 42px;
              font-weight: 300;
              font-size: 18px;
              font-family: $font-family-suisse;
            }
            .cell-info {
              margin-left: 38px;
            }
          }
          &:nth-child(2),
          &:nth-child(5) {
            color: $light-green;
          }
          &:nth-child(5) {
            text-align: right;
          }
        }
      }
    }
    @media (max-width: 768px) {
      th, td {
        width: auto !important;
        font-size: 10px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  table {
    th:nth-child(3) {
      text-align: center;
    }
  }
  .avatar-cell > .avatar {
    display: none !important;
  }
}

.litx-table {
  padding: 0 54px;

  .resend-text {
    margin-right: 20px;
  }
  &__title {
    font-family: $font-family-speziaserif;
    font-size: 46px;
    line-height: 52px;
    letter-spacing: -0.03em;
    color: #0b0f1b;
  }
  &__selected-document {
    display: flex;
    align-items: center;
    color: $light-green;
  }

  &__tab-row {
    display: flex;
    max-width: 50%;
    align-items: center;
  }
  &__tab {
    color: red;
    margin-right: 45px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: $dark-green-bg;
    &.active {
      border-bottom: 4px solid $light-green;
    }
  }

  input[type="search"] {
    border: 1px solid #003732;
  }
  .search-svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
  }
}
