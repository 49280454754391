.fa-method-container {
  cursor: pointer;

  & > * {
    cursor: pointer !important;
  }
}

.not-configured {
  color: #0b0f1b;
  opacity: 28%;
  min-width: auto !important;
}

.profile-middle-col-start {
  justify-content: flex-start !important;
  margin-left: 25px;
  color: #00afaa;
}
