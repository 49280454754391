@import '../../common/variables';

.login-form-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.login-form-header {
  font-size: 36px;
  margin-bottom: 20px;
}

.login-form-inputs-container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.login-reset-password-link {
  margin-top: 20px;
  margin-bottom: 20px;
}

.login-actions-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
}

.login-btn {
  align-self: center;
  width: 150px;

  @media (min-width: $sm) {
    width: 300px;
  }
}

.login-btn__hidden {
  visibility: hidden;
}

.login-no-account {
  margin-top: 20px;
  text-align: center;
}
