.rename-modal__input_container {
  position: relative;
}

.rename-modal__input {
  padding-right: 40px !important;
}

.rename-modal__input_extension {
  position: absolute;
  right: 10px;
  top: 9px;
  opacity: 40%;
}