@import "../../../../common/variables";

.modal-container {
  position: relative;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.loader {
  height: 48px;
  z-index: 2;
  position: absolute;
  top: calc(50% - 24px);
  right: calc(50% - 24px);
  animation: rotation 1s infinite linear;
}

.table-container {
  background-color: #fff;
  min-height: 120px;
  max-height: 100%;
  width: 38rem;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
  border: 2px solid #dddcd9;
  margin-top: 10px;
  overflow-y: auto;
}

.table-container-hidden {
  visibility: hidden;
}

.table-row {
  color: #0b0f1b;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 21px;
}

.table-row-folder-hover {
  &:hover {
    background-color: $light-green;
    cursor: pointer;
  }
}

.table-row:not(:last-child) {
  border-bottom: 2px solid #dddcd9;
}

.buttons-container {
  display: flex;
  justify-content: center;
  column-gap: 30px;
  margin-top: 80px;
}

.file-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
