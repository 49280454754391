@import '../../common/variables';

.close-banner {
  background: #f55519;
  height: 64px;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 10px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.case-detail {
  padding: 10px 20px;
  background-color: $light-beige-bg !important;

  .party-action {
    font-size: 25px;
  }

  .plus-icon {
    width: 11.5px;
    height: 11.5px;
    margin-bottom: 3px;
    margin-right: 2px;
  }

  .avatar {
    color: #fff;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 42px;
    height: 42px;
    font-weight: 600;
  }

  .folder-case-text {
    color: $light-green;
    margin-right: 5px;
    font-size: 18px;
  }

  .folder-text {
    font-size: 18px;
    margin-left: 5px;
  }

  .admin-badge {
    background-color: #003732 !important;
    margin-left: 10px;
  }

  .intervenor-badge {
    background-color: #007d79 !important;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 700;
    font-family: $font-family-suisse;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-top: 5px;
  }

  .invite-case {
    font-family: $font-family-suisse;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    font-feature-settings:
      'tnum' on,
      'lnum' on;
    color: #ffffff;
  }

  .case-action {
    margin-left: 13px;
    font-size: 25px;
    line-height: 30px;
  }

  .documents-background {
    background-color: white;
    position: absolute;
    right: 0;
    left: 0;
    min-height: -webkit-fill-available;
    //min-height: calc(100vh - 495px);
  }

  .search-svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
  &__documents {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    padding: 10px 20px;
    margin-top: 60px;
    max-width: 1344px !important;

    input[type='search'] {
      border: 1px solid #003732;
      height: 48px;
    }

    .dropdown {
      font-size: 25px;
    }
    .title {
      font-family: $font-family-speziaserif;
      font-weight: 400;
      font-size: 36px;
      line-height: 66px;
      color: #353535;
    }
    .header-row {
      display: flex;
      margin-right: 10px;
      align-items: center;
      justify-content: flex-start;
      font-weight: 300;
      font-size: 16px;
      line-height: 16px;
      color: #0b0f1b;
      & > svg {
        color: $light-green;
      }
    }
    .upload-text {
      margin-left: 10px;
    }
    .dragdrop {
      text-align: center;
      height: 222px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      svg {
        color: $light-green;
      }
      p {
        margin-top: 11px;
        max-width: 200px;
        color: $light-green;
        font-size: 16px;
      }
    }
  }
  &__details {
    margin-bottom: 22px;
    .case-title {
      font-family: $font-family-speziaserif;
      font-weight: 400;
      font-size: 46px;
      line-height: 52px;
      letter-spacing: -0.03em;
      min-height: 52px;
    }
    .case-number,
    .case-location {
      font-family: $font-family-suisse;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.05em;
      color: #0b0f1b;
      min-height: 24px;
    }
    .case-created,
    .case-type {
      font-family: $font-family-suisse;
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: #0b0f1b;
    }
    .cta-row {
      display: flex;
      align-items: center;
      margin-top: 58px;
    }
    .versus-panels {
      .label {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px;
        text-align: center;
        letter-spacing: 0.1em;
        color: #5b5c5f;
        margin-top: 10px;
      }
      .panel {
        background-color: white;
        border-radius: 20px;
        padding: 20px 0;
        overflow: hidden;
        &__title {
          font-family: $font-family-speziaserif;
          font-weight: 400;
          font-size: 30px;
          line-height: 30px;
          letter-spacing: -0.03em;
          color: #0b0f1b;
          display: flex;
          align-items: center;
        }
        &__row {
          border-bottom: 1px solid #e2dfd6;
          display: flex;
          justify-content: space-between;
          min-height: 54px;
          .icons-wrapper {
            height: 42px;
            width: 42px;
            border-radius: 50%;
          }

          .count {
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            letter-spacing: 0.05em;
            color: #000000;
          }
        }

        .user {
          position: relative;
          padding: 10px 0;
          .count {
            position: absolute;
            bottom: 4%;
            right: 4px;
          }
          &__name,
          &__title {
            font-weight: 300;
            font-size: 14px;
            line-height: 12px;
            letter-spacing: 0.05em;
          }
          &__name {
            margin-top: 10px;
            margin-bottom: 10px;
          }
          &__title {
            font-weight: 600;
          }
        }
      }
    }

    .neutral-panel {
      @extend .versus-panels;
      margin-top: 10px;
      justify-content: center;
    }
  }

  &__table {
    .new-badge {
      background-color: #f55519 !important;
      margin-left: 10px;
    }
    .sort-icon {
      width: 20px;
      height: 12px;
      margin-left: 10px;
    }
    .file-icon {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    th:first-child {
      width: 50%;
    }

    thead {
      th,
      td {
        padding: 10px;
      }
      tr {
        padding: 10px;
        background-color: $light-beige-bg;
      }
    }
  }
}

.dzu-inputLabel {
  color: #00afaa;
  font-weight: 400;
}

.dzu-dropzone {
  overflow-x: hidden;
  overflow-y: auto;
  border: 2px solid #eceae4;
  border-radius: 0;
  min-height: 232px;
  margin-left: 10px;
}

.dzu-previewContainer {
  z-index: none;
}

.uppy-DashboardContent-bar {
  z-index: 1 !important;
}

.uppy-StatusBar {
  z-index: 1 !important;
}

.uppy-StatusBar-actions {
  justify-content: flex-end !important;
  display: flex !important;
  position: unset !important;
}

.uppy-StatusBar-actionBtn--upload {
  background-color: #003732 !important;
}

.uppy-Dashboard-AddFiles {
  border: unset !important;
}

.uppy-Dashboard-AddFiles-title {
  color: $light-green !important;
}

.uppy-DashboardTab-name {
  color: $light-green !important;
}

.uppy-Dashboard-inner {
  background-color: unset !important;
  border-radius: unset !important;
  border: 2px solid #eceae4 !important;
  width: 100%;
}

.uppy-StatusBar-actionBtn--upload {
  background-color: #003732 !important;

  &:hover {
    background-color: #003732 !important;
  }
}

.uppy-DashboardContent-back,
.uppy-Dashboard-browse,
.uppy-DashboardContent-addMore {
  color: #00afaa !important;

  &:hover {
    color: #00afaa !important;
  }
}
