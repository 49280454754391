@import "../../../../common/variables";

.aiChatHelp__buttons_container {
  display: inline-flex;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.aiChatHelp__help_checkbox {
  margin-top: 10px;
  font-size: 14px;
  text-wrap: wrap;
}

.aiChatHelp__video {
  width: 600px;
  height: 400px;
  @media (max-width: 840px) {
    width: 100%;
    height: 200px;
  }
}
