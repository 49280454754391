@import "../../common/variables";

.requests-dropdown {
  padding: 0;
  position: relative;
  width: 371px;
  margin-right: 20px;

  .modal-close {
    z-index: 101;
    top: 11px;
  }

  .requests-title {
    font-family: $font-family-speziaserif;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 18px;
    letter-spacing: -0.02em;
    font-feature-settings: "tnum" on, "lnum" on;
  }

  &__header {
    display: flex;
    background: #f55519;
    padding: 10px;
    color: white;
    font-family: $font-family-speziaserif;
    font-size: 28px;
    height: 55px;
    align-items: center;
    justify-content: space-between;
  }

  .requests-close {
    color: white;
    font-size: 35px;
  }

  &__branding-image {
    position: absolute;
    top: 0;
    right: -21px;
    transform: scale(0.8);
  }

  &__card {
    background-color: white;
    min-width: 370px;
    padding: 9px 25px;
    z-index: 100;
    border: 1.5px solid #f55519;
    position: relative;

    .request-header {
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      font-family: $font-family-suisse;
      letter-spacing: 0.03em;
      margin-bottom: 5px;
      color: #003732;
    }
    .request-case-title {
      font-family: $font-family-speziaserif;
      color: $light-green;
      font-size: 18px;
      color: #0b0f1b;
    }
    .card-body {
      display: flex;
      align-items: center;
      .avatar {
        height: 42px;
        width: 42px;
        background-color: papayawhip;
        border-radius: 50%;
      }
      .request-copy {
        margin-left: 20px;
        color: #363636;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: 0.02em;
      }
    }
    .case-info-list {
      display: flex;
      flex-direction: column;
      font-family: $font-family-suisse;
      letter-spacing: 0.05em;
      line-height: 24px;
      font-weight: 400;
      color: #363636;
    }
    .case-request-action-row {
      display: flex;
      align-items: center;
      margin-top: 10px;
      justify-content: space-evenly;
      color: $light-green;
      font-weight: 400;
    }
  }
}
