.captcha__wrapper {
  display: flex;
  justify-content: flex-start;

  @media screen and (max-width: 1500px) {
    transform: scale(0.9);
    transform-origin: 0 0;
  }

  @media screen and (max-width: 400px) {
    transform: scale(0.88);
    transform-origin: 0 0;
  }
}
