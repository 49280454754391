@import "../../common/variables";

.subscribe {
  background: $light-beige-bg;
  display: flex;
  justify-content: center;
  height: 100vh;
}

.subscribe-form {
  position: relative;
  max-width: 682px;
  top: -35px;
  @media (max-width: 768px) {
    max-width: 100%;
    top: 0;
  }
  &__title {
    text-align: center;
    font-family: $font-family-speziaserif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 34px;
    letter-spacing: -0.045rem;
    color: #222222;
    @media (max-width: 768px) {
      font-size: 32px;
      line-height: 30px;
      letter-spacing: -0.035rem;
      margin-top: 2rem;
    }
  }
  &__subtitle {
    text-align: center;
    font-weight: 300;
    font-size: 24px;
    font-family: "suisse-reg", Arial, Helvetica, sans-serif !important;
    line-height: 72px;
    letter-spacing: 0.02em;
    color: #000000;
    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.01em;
      margin-top: 2rem;
    }
  }
  &__branding-image {
    position: fixed;
    bottom: 0;
    right: 0;
  }

  .form-subcopy {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #0b0f1b;
  }

  .subscribe__centered_button_row {
    width: 100%;
    display: flex;
    justify-content: center;
    .btn {
      margin: 10px;
    }
  }

  #formExp {
    font-size: 12px;
    line-height: 24px;
  }
}
