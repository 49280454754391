@font-face {
    font-family: "speziaserif";
    src: url("../assets/fonts/SpeziaSerif-RegularC.otf")
        format("opentype");
    font-style: normal;
}
@font-face {
    font-family: "suisse-reg";
    src: url("../assets/fonts/SuisseIntl-Regular.otf")
        format("opentype");
    font-style: normal;
}

// Typography
$font-family-speziaserif: "speziaserif", serif; 
$font-family-suisse: "suisse-reg", Arial, Helvetica, sans-serif; 

// Colors
$light-beige-bg: #ECEAE4;
$dark-green-bg: #003732;
$light-green: #00AFAA;
$overlay-green: rgba(0, 55, 50, 0.72);

// Breakpoints
$sm: 576px;
$md: 768px;
$lg: 992px;
