@import "../../common/variables";

.dashboard-welcome {
  height: 100%;
  background: $light-beige-bg;
  padding: 8px 20px;

  .create-case-welcome {
    text-align: center;
    &__title {
      font-family: $font-family-speziaserif;
      font-weight: 400;
      font-size: 42px;
      line-height: 34px;
      letter-spacing: -0.045em;
      color: #222222;
    }
    &__subcopy {
      font-weight: 300;
      font-size: 24px;
      line-height: 72px;
      letter-spacing: 0.02em;
    }
    .btn {
      width: fit-content;
      padding: 10px 20px;
    }
  }
  .join-case-welcome {
    text-align: center;
    margin: 0 auto;
    &__title {
      font-family: $font-family-speziaserif;
      font-weight: 400;
      font-size: 42px;
      line-height: 34px;
      letter-spacing: -0.045em;
      color: #222222;
    }
    &__subcopy {
      font-weight: 300;
      font-size: 24px;
      letter-spacing: 0.02em;
    }
    a {
      text-decoration: none;
    }
    .btn {
      width: fit-content;
      padding: 10px 20px;
      display: block;
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }

  &__branding-image {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 100;
  }
}
