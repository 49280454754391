@import "./common/variables";
@import "./common/common";

body {
  margin: 0;
  // use of !important to override _reboot.scss from bootstrap package
  font-family: $font-family-suisse !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
