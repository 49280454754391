@import "../../common/variables";

.settings {
  padding: 10px 20px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    padding: 0px;
  }

  &__header {
    margin-bottom: 36px;

    svg {
      height: 34px;
      width: 35px;
      margin-right: 12px;
    }

    .icons-wrapper {
      position: absolute;
      top: 10px;
      right: 10px;
      height: 42px;
      width: 42px;
      background-color: #b23e12;
      border-radius: 50%;
    }
  }

  &__container {
    padding: 10px 45px;
    @media screen and (max-width: 768px) {
      font-size: 16px;
      padding: 5px;
    }
  }

  .avatar {
    color: #fff;
    padding: 2px;
    border-radius: 100%;
    width: 42px;
    height: 42px;
  }

  &__title {
    font-family: $font-family-speziaserif;
    font-style: normal;
    font-weight: 400;
    font-size: 46px;
    line-height: 52px;
    letter-spacing: -0.03em;
    color: #0b0f1b;
    margin-bottom: 24px;
  }

  &__tab-row {
    display: flex;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }

  }
  &__tab {
    color: red;
    margin-right: 45px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: $dark-green-bg;
    &.active {
      border-bottom: 4px solid $light-green;
      width: auto;
    }

    @media screen and (max-width: 530px) {
      margin-right: 25px;
      font-size: 16px;
    }
  }

  .account-settings {
    .radio-select {
      width: 24px;
      height: 24px;
      margin-top: 10px;
    }
    .switch {
      margin-top: 15px !important;
      width: 46px !important;
      height: 24px !important;
      border-radius: 2em !important;
    }
    .form-check-input:checked {
      background-color: #00afaa;
      border-color: #00afaa;
    }
    .form-check-input.is-invalid {
      border-color: #00afaa;
    }
    .settings-header {
      display: block;
      font-family: $font-family-suisse;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.02em;
      color: #0b0f1b;
      margin-bottom: -20px;
    }
    .settings-sub-header {
      font-family: $font-family-suisse;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      letter-spacing: 0.05em;
      color: #3f3f3f;
    }
    .no-bottom-border {
      border-bottom: unset !important;
    }
    .subscription {
      &__row {
        border-bottom: 1px solid #cecece;
        display: flex;
        // justify-content: space-between;
        // padding: 20px 0;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 50px;
        color: #0b0f1b;

        .left-col {
          min-width: 120px;
        }

        .middle-col {
          flex: 1;
        }
        .middle-col-center {
          flex: 1;
          justify-content: center;
          display: flex;
        }
        .profile-middle-col {
          flex: 1;
          justify-content: flex-end;
          display: flex;
          margin-right: 25px;
        }
        .right-col {
          color: $light-green;
          font-family: $font-family-suisse;
        }
        .right-col-black {
          color: #0b0f1b;
          font-family: $font-family-suisse;
        }
      }
    }

    .users {
      &__row {
        border-bottom: 1px solid #cecece;
        display: flex;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 50px;
        color: #0b0f1b;

        .left-col {
          min-width: 220px;
        }
        .middle-col {
          flex: 1;
        }
        .right-col {
          color: #00afaa;
          font-weight: 400;
        }
      }
    }

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 50px;
      color: #0b0f1b;
      &.right-col {
        font-weight: 400;
      }
    }
  }
}
