.input-phone-container {
  position: relative;
}

.input-phone {
  padding-left: 30px !important;
}

.country-code-prefix {
  position: absolute;
  top: 50%;
  transform: translateY(16%);
  left: 10px;
}
