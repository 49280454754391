@import "../../../common/variables";

.backdrop {
  background-color: $overlay-green;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
}

.navigation-modal {
  transition: width 2s ease;
  &__sidebar {
    position: fixed;
    top: 0;
    height: 100vh;
    left: 0;
    width: 275px;
    padding: 20px;
    background: white;
    z-index: 200;
  }

  .modal-close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-weight: 700;
    font-size: 24px;
    cursor: pointer;
    color: $light-green;
    transition: width 2s ease;
  }
  .nav-wrap {
    color: $light-green;
    .nav-list-item {
      padding: 5px;
      margin-bottom: 15px;
      cursor: pointer;
      a {
        color: $light-green;
        text-decoration: none;
        display: flex;
        align-items: center;
        svg {
          margin-right: 8px;
        }
      }
      &.with-subnav {
        .subnav {
          &.settings-subnav {
            margin-left: 30px;
            border-left: 1px solid #c4c4d4;
            padding-left: 20px;
          }
        }
      }
    }
  }
}

.base-modal {
  align-items: center;
  background-color: $light-beige-bg;
  border: 1px solid gray;
  border-radius: 6px;
  padding: 41px;
  left: 50%;
  width: fit-content;
  height: fit-content;
  min-width: 300px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  z-index: 2000;
  &.wide {
    min-width: 762px;
    @media screen and (max-width: 850px) {
      min-width: 0;
    }
  }
}

.modal-header {
  font-family: $font-family-speziaserif;
  font-weight: 400;
  font-size: 42px;
  line-height: 48px;
  letter-spacing: -0.05em;
  color: #151515;
}

@media (min-width: 600px) {
  .modal {
    min-width: 600px;
  }
}
