.bread-crumb-segment {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.16px;
}

.bread-crumb-segment-separator {
  color: #c8c5bd;
  margin-right: 8px;
  margin-left: 8px;
}

.bread-crumb-segment-button {
  @extend .bread-crumb-segment;

  cursor: pointer;
  color: #00afaa;
}
