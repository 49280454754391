.filesSection__chat_container {
  display: flex;
  flex-direction: row;
  height: 600px;
  width: 100%;
}

.filesSection__table {
  width: 100%;
  min-width: 300px !important;
  display: table;
  border-collapse: collapse;
  border: 4px solid rgb(236, 234, 228);
  table-layout: auto;

  thead th {
    background: #eceae4;
    color: black;
    font-weight: 400;
    height: 48px;
  }

  thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  tbody {
    display: block;
    height: 500px;
    width: 100%;
    overflow-y: auto;
    tr {
      width: 100%;
      height: 75px;
      border-bottom: 1px solid rgb(236, 234, 228);
    }
   }
  
  th, td {
    overflow: clip;
    padding: 0px !important;
  }
  
  th:first-child, td:first-child {
    width: 60% !important;
    padding-left: 10px !important;
  }

  td:not(:first-child), th:not(:first-child) {
    width: 15% !important;
    text-align: center !important;
  }

  th {
    font-weight: 600 !important;
  }
  
  tbody::-webkit-scrollbar {
    background-color: #f0f0f0;
    width: 8px;
    border: 3px solid #f0f0f0;
    border-radius: 10px;
    height: 50px;
  }
  
  tbody::-webkit-scrollbar-thumb {
    width: 10px;
    border-radius: 10px;
    background-color: #c0c0c0;
    background-color: rgba(0, 175, 170, 0.3);
  }
  
  tbody::-webkit-scrollbar-thumb:hover {
    background-color: #c0c0c0;
    background-color: rgba(0, 175, 170, 0.6);
    cursor: pointer;
  }
}

@media screen and (max-width: 491px) {
  .filesSection__table {
    th, td {
      font-size: 12px;
      padding: 0px !important;
      width: auto !important;
    }

    th:nth-child(2), td:nth-child(2) {
      display: none;
    }
  }
}

@media screen and (max-width: 850px) {
  .filesSection__table {
    th, td {
      font-size: 12px;
      padding: 1px !important;
      width: auto !important;
    }
  }
}

@media screen and (max-width: 1000px) {
  .filesSection__table {
    width: auto !important;
    th, td {
      font-size: 14px;
      padding: 1px !important;
      width: auto !important;
    }
  }
}

.filesSection__divider {
  background-color: rgba(0, 0, 0, 0.4);
  cursor: ew-resize;
  width: 4px;
  border: 3px solid rgba(0, 175, 171, 0);
  border-radius: 15px;
  vertical-align: middle;
  margin-left: 9px;
  margin-right: 9px;
  transition: all 0.4s ease;
}

.filesSection__divider:hover, .filesSection__divider:active {
  background-color: rgba(0, 0, 0, 0.7);
  width: 4px;
  margin-left: 9px;
  margin-right: 9px;
}

@media screen and (max-width: 800px) {
  .filesSection__divider {
    display: none;
  }

  #right-panel {
    display: none;
  }
}

.filesSection__documents {
  margin-top: 0px;
  margin-bottom: 50px;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 100% !important;
  height: 750px;
}

.filesSection__file-table-cell {
  padding: 10px;
  background: none;
  cursor: pointer;
}

.filesSection__file-table-cell-selected {
  background-color: rgba(0, 175, 170, 0.2) !important;
}
