@import "../../../common/variables";

.backdrop {
  background-color: $overlay-green;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2000;
}

.base-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $light-beige-bg;
  border: 1px solid gray;
  border-radius: 6px;
  padding: 41px;
  left: 50%;
  width: fit-content;
  height: fit-content;
  min-width: 300px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  z-index: 3000;
  max-width: 700px;
  &.wide {
    min-width: 762px;
    @media screen and (max-width: 850px) {
      min-width: 0;
    }
  }
  &.max {
    min-width: 1200px !important;
    height: calc(100vh - 50px);
    @media screen and (max-width: 850px) {
      min-width: 0;
    }
  }

  @media screen and (max-width: 850px) {
    font-size: 14px;
    h1 {
      font-size: 34px;
    }
  }
  @media screen and (max-width: 400px) {
    padding: 20px;
    font-size: 12px;
    h1 {
      font-size: 24px;
    }
  }
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-weight: 700;
  font-size: 24px;
  cursor: pointer;
}

.modal-header {
  font-family: $font-family-speziaserif;
  font-weight: 400;
  font-size: 42px;
  line-height: 48px;
  letter-spacing: -0.05em;
  color: #151515;
}

@media screen and (min-width: 800px) {
  .base-modal > .row {
    width: 500px;
  }
}
