@import '../../common/variables';


#dropdown-dots {
    color: $light-green;
    margin-left: 10px;
}

.dropdown-menu {
    border: 1px solid $light-green;

    .menu-item {
        padding: 10px 15px;
        background: white;
    }
}
