@import '~bootstrap/scss/bootstrap';
@import './common/common.scss';
@import './common/variables';

.pointer {
  cursor: pointer;
}

.App {
  font-family: $font-family-suisse;
}

#root {
  height: 100%;
}

.colored-toast.swal2-icon-success {
  background-color: #00e0da !important;
}

.colored-toast.swal2-icon-error {
  background-color: red !important;
}

.colored-toast .swal2-title {
  color: black;
}

.colored-toast .swal2-close {
  color: white;
}

.colored-toast .swal2-html-container {
  color: #0b0f1b;
  font-size: 14px;
}

.swal2-toast-shown .swal2-container {
  min-width: 350px !important;
}
