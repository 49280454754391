@import '../../common/variables';

.ReactInputVerificationCode__container {
  display: flex;
  align-self: center;
  margin-bottom: 40px;

  --ReactInputVerificationCode-itemWidth: 42px;
  --ReactInputVerificationCode-itemHeight: 56px;
  --ReactInputVerificationCode-itemSpacing: 11px;
}

.ReactInputVerificationCode__item {
  background-color: #fff;
}

.verification-form-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.verification-form-header {
  font-size: 36px;
  margin-bottom: 40px;
}

.verification-form-subheader {
  font-size: 16px;
  margin-bottom: 40px;
  text-align: center;
  font-weight: 600;
}

.verification-form-actions-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.verification-form-verify-btn {
  align-self: center;
  width: 150px !important;

  @media (min-width: $sm) {
    width: 300px !important;
  }
}

.verification-form-reset {
  cursor: pointer;
  color: $light-green;
  text-align: center;
  margin-top: 20px;
}
