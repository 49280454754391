@import '../../common/variables';

.logo-img-container {
  display: flex;
  justify-content: flex-end;

  @media (min-width: $md) {
    justify-content: flex-start;
  }
}

.login-img-logo {
  height: 80px;
}