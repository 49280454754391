@import "../../common/variables";
$sidebar-width: 500px;

.registration__registration-container {
  display: flex;
  max-width: 100%;
  background-color: $dark-green-bg;
}

.registration {
  max-height: 100%;
  background: $dark-green-bg;
  padding: 30px 60px;

  .logo {
    max-height: 66px;
    width: 100%;
  }

  @media screen and (max-width: 1250px) {
    .logo {
      max-height: 66px;
      width: 50px;
    }
  }
}

.registration__getting_started {
  display: flex;
  flex-direction: column;
  justify-content: right;
  color: white;
  text-align: center;
  width: 100%;
  min-width: 350px;

  @media screen and (min-width: 1500px) {
    padding-left: 10px;
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }

  .registration__welcome_video {
    display: flex;
    justify-content: center;

    iframe {
      width: 100%;
      height: 415px;
      max-width: 800px;
      max-height: 415px;
      border: none;
      justify-content: center;
    }
  }

  .benefitsSection {
    text-align: center;
    margin-top: 100px;
    padding: 500px;
  }

  h3 {
    padding-top: 0;
    color: white;
    padding-bottom: 20px;
  }
}

.registration-form {
  background-color: $light-beige-bg;
  border-radius: 5px;
  padding: 3rem;

  @media screen and (min-width: 1500px) {
    margin-right: 100px;
  }

  &__title {
    font-family: $font-family-speziaserif;
    font-style: normal;
    font-weight: 400;
    font-size: 31px;
    line-height: 34px;
    letter-spacing: -0.05em;
    color: #0a0a0a;
  }
  .agreement-text {
    color: black;
  }

  .agreement-text-link {
    color: $light-green;
    cursor: pointer;
  }

  .btn-primary {
    margin-left: 12px;
    max-width: 116px;
    text-align: center;
  }
  .centered-button-row {
    align-items: center;
    justify-content: left;
    a {
      text-align: center;
    }
    .btn-primary {
      background-color: $light-green;
      &:hover {
        background-color: #005951;
      }
    }
  }
}

.registration-confirmation {
  padding: 30px 60px;
  background: $dark-green-bg;
  max-height: 100vh;
  display: flex;
  .confirmation-copy {
    display: flex;
    flex-direction: column;
    margin-top: 120px;
    h1 {
      font-family: $font-family-speziaserif;
      font-style: normal;
      font-weight: 400;
      font-size: 66px;
      line-height: 66px;
      letter-spacing: -0.03em;
      color: #fff;
      margin-bottom: 30px;
    }
    span {
      font-weight: 300;
      font-size: 26px;
      line-height: 35px;
      letter-spacing: 0.04em;
      color: #ffffff;
    }
    .resend-text {
      margin-top: 30px;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.02em;
    }
    .resend-link {
      font-size: 16px;
      cursor: pointer;
      color: #00afaa;
      line-height: 21px;
      letter-spacing: 0.02em;
    }
  }
  .logo {
    max-height: 122px;
    max-width: 108px;
    margin-top: 20px;
  }
}

// link style here matches across views
// Move to main or global when scss structure progresses
.password-reset-link {
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: $light-green;
}

.registration {	
  max-height: 100%;	
  background: $dark-green-bg;	
  padding: 30px 60px;	
  display: flex;	
  flex-direction: column;	

  .logo {	
    margin: 1em 0 2em 0;	
    max-width: 100%;	
    max-height: 5em;	
    display: flex;	
    justify-content: flex-start;	
    align-items: flex-end;	

    img {	
      margin-right: 2em;	
    }	

    .logoheadline {	
      color: white;	
      font-size: 1.5em;	
      padding: 0;	
      line-height: 1.5em;	
      margin: -0.35em;	
    }	
  }	
}	

.registration-form {	
  background-color: $light-beige-bg;	
  border-radius: 5px;	
  padding: 60px;	
  &__title {	
    font-family: $font-family-speziaserif;	
    font-style: normal;	
    font-weight: 400;	
    font-size: 2em;	
    line-height: 1.1em;	
    letter-spacing: -0.05em;	
    color: #0a0a0a;	
    text-align: center;	
  }	
  .agreement-text {	
    color: black;	
  }	

  .agreement-text-link {	
    color: $light-green;	
    cursor: pointer;	
  }	

  .btn-primary {	
    width: 116px;	
    text-align: center;	
  }	
  .centered-button-row {	
    a {	
      text-align: center;	
    }	
    .btn-primary {	
      background-color: $light-green;	
      &:hover {	
        background-color: #005951;	
      }	
    }	
  }	

  @media (max-width: 400px) {	
    padding: 1em 1em;	
    .logo {	
      margin: 0.5em 0 1em 0;	
    }	
    &__title {	
      font-size: 1em;	
      line-height: 1em;	
      letter-spacing: -0.10em;	
    }	
  }	

  @media (max-width: 600px) {	
    padding: 1.5em 1.5em;	
    .logo {	
      margin: 0.5em 0 1em 0;	
    }	
    &__title {	
      font-size: 1.5em;	
      line-height: 1em;	
    }	
  }	
}	

.registration-confirmation {	
  padding: 30px 60px;	
  background: $dark-green-bg;	
  max-height: 100vh;	
  display: flex;	
  .confirmation-copy {	
    display: flex;	
    flex-direction: column;	
    margin-top: 120px;	
    h1 {	
      font-family: $font-family-speziaserif;	
      font-style: normal;	
      font-weight: 400;	
      font-size: 66px;	
      line-height: 66px;	
      letter-spacing: -0.03em;	
      color: #fff;	
      margin-bottom: 30px;	
    }	
    span {	
      font-weight: 300;	
      font-size: 26px;	
      line-height: 35px;	
      letter-spacing: 0.04em;	
      color: #ffffff;	
    }	
    .resend-text {	
      margin-top: 30px;	
      font-size: 16px;	
      line-height: 21px;	
      letter-spacing: 0.02em;	
    }	
    .resend-link {	
      font-size: 16px;	
      cursor: pointer;	
      color: #00afaa;	
      line-height: 21px;	
      letter-spacing: 0.02em;	
    }	
  }	
  .logo {	
    max-height: 122px;	
    max-width: 108px;	
    margin-top: 20px;	
  }	
}	

.icon-container {	
  display: flex;	
  align-items: flex-start;	
}	

.icon-image {	
  float: left;	
  margin-right: 10px;	
  max-width: 100px;	
  width: 3em;	
  height: 3em;	
  &.add-recipient {	
    width: 3.45em;	
    height: 3.45em;	
  }	
  &.shield-nudge {	
    margin-top: 0.4em;	
  }	
  &.tool-kit-nudge {	
    margin-top: 0.6em;	
  }	
}	

.text-paragraph {	
  margin: 0; /* Remove any default margin to avoid extra spacing */	
  padding-bottom: 2em;	
}	

// link style here matches across views	
// Move to main or global when scss structure progresses	
.password-reset-link {	
  text-decoration: none;	
  font-weight: 400;	
  font-size: 16px;	
  line-height: 18px;	
  color: $light-green;	
}	

