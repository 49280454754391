@import '../common/variables';
@import '../common/common';

#inner-layout {
  height: calc(100vh - 128px);
  margin-left: 0;
  transition: all 250ms ease-in-out;
  margin-top: 84px;
}

#inner-layout.open {
  margin-left: 75px;
  transition: all 250ms ease-in-out;
}

.header-left.open {
  opacity: 0;
}

.onboarding-layout {
  background-color: $light-beige-bg !important;
  height: 100%;
  overflow-y: auto;
}

.settings-subnav {
  height: 0px;
  opacity: 0;
  transition: all 250ms ease-in-out;
  margin-bottom: -10px;
  visibility: hidden;
}

.settings-subnav.open {
  height: 180px;
  opacity: 1;
  transition: all 250ms ease-in-out;
  margin-top: 1.5rem !important;
  margin-bottom: 0;
  visibility: visible;
}

.onboarding-layout-logo {
  margin-left: 66px;
  margin-top: 44px;
  transition: all 250ms ease-in-out;
  @media (max-width: 768px) {
    display: none;
  }
}

.dropdown-icon {
  width: 14px;
  height: 8px;
  margin-left: 50px;
}

.menu-icon {
  width: 32px;
  height: 32px;
}

.branding-image {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
  @media (max-width: 768px) {
    display: none;
  }
}

.logo {
  margin-bottom: 140px;
}

.sidebar {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  width: 375px;
  background: $light-beige-bg;
  padding: 20px;
}

.swal2-container {
  z-index: 4000 !important;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-weight: 700;
  font-size: 24px;
  cursor: pointer;
  color: $light-green;
  transition: width 2s ease;
}
.nav-wrap {
  color: $light-green;
  .nav-list-item {
    padding: 5px;
    font-weight: 600;
    margin-bottom: 15px;
    cursor: pointer;
    a {
      color: $light-green;
      text-decoration: none;
      display: flex;
      align-items: center;
      svg {
        margin-right: 8px;
      }
    }
    &.with-subnav {
      .subnav {
        &.settings-subnav {
          margin-left: 30px;
        }
      }
    }
  }
}

.enterprise-layout__container {
  height: 100%;
  background-color: $dark-green-bg;

  @media (min-width: $md) {
    display: flex;
  }
}

.enterprise-layout__left-panel {
  background-color: $light-beige-bg;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-basis: content;
  flex-grow: 1;

  @media (min-width: $md) {
    max-width: 600px;
  }
}

.enterprise-layout__left-panel-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 30px;
  row-gap: 50px;
}

.enterprise-layout__right-panel {
  height: 100%;
  width: 100%;
  display: none;
  padding: 0 50px 30px 50px;
  flex-basis: content;
  flex-grow: 3;

  @media (min-width: $sm) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.enterprise-layout__right-panel__header {
  font-size: 24px;
  line-height: 24px;
  color: white;
  font-weight: 500;
  max-width: 50px;

  @media (min-width: $md) {
    font-size: 46px;
    line-height: 46px;
  }
}

.enterprise-layout__img {
  width: 80%;

  @media (min-width: $lg) {
    max-width: 700px;
  }
}

.enterprise-layout__footnote {
  color: white;
}
