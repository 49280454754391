@import '../../common/variables';

.create-case {
  background: $light-beige-bg;
  padding: 8px 20px;

  &__branding-image {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 100;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .small-text {
    font-size: 12px;
  }
}

.create-case-form {
  &__title {
    font-family: $font-family-speziaserif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 34px;
    letter-spacing: -0.045em;
    color: #222222;
  }
  .add-party-link {
    color: $light-green;
    margin-left: 5px;
  }

  .centered-button-row {
    a {
      text-align: center;
    }
    .btn {
      margin: 0 auto;
    }
  }
}

.add-neutral-party {
  input {
    cursor: pointer;
  }
}
