@import '../../../common/variables';

.ai-chat__container {
  height: 600px;
  width: 100%;
  min-width: 400px;
  border-left: solid 4px rgb(236, 234, 228);
  border-bottom: solid 4px rgb(236, 234, 228);
  display: grid;
  grid-template-columns: 1fr 4fr;
}

.ai-chat__container-section {
  display: grid;
  height: 600px;
  grid-template-rows: 48px 1fr;
  border-right: solid 4px rgb(236, 234, 228);
  row-gap: 10px;
}

.ai-chat__header {
  background-color: rgb(236, 234, 228);
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
}

.ai-chat__title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  p {
    font-size: 20px;
    font-weight: 600;
  }
}

.ai-chat__title-container {
  display: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.ai-chat__header_new_chat_button {
  height: 100%;
  cursor: pointer;
}

.ai-chat__history {
  height: 100%;
}


.ai-chat__window {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 20px;
  height: 530px;
}

.ai-chat__prompt-container {
  width: 85%;
  align-self: center;
  position: relative;
  margin-bottom: 10px;
}

.ai-chat__prompt-header-container {
  display: flex;
  justify-content: space-between;
}

.ai-chat__prompt-char_count {
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
  opacity: 60%;
}
.ai-chat__prompt-char_count_limit {
  color: red;
  opacity: 100%;
}

.ai-chat__prompt-textarea {
  display: block;
  padding: 12px;
  padding-right: 40px;
  width: 100%;
  border-radius: 10px;
  border-color: rgb(236, 234, 228);
  max-height: 200px;
  &:focus {
    outline-color: $light-green;
  }
}

.ai-chat__prompt-send-button {
  height: 24px;
  fill: $light-green;
  position: absolute;
  bottom: 12px;
  right: 12px;
  cursor: pointer;
}

.ai-chat__prompt-send-button_disabled {
  opacity: 0.5;
  cursor: default;
}

.ai-chat__messages_container {
  width: 85%;
  height: 100%;
  align-self: center;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  overflow-y: auto;
}

.ai-chat__message_container {
  display: flex;
  flex-direction: column;
  column-gap: 10px;
  white-space: pre-wrap;
}

.ai-chat__message_logo {
  height: 28px;
  fill: $light-green;
  padding-bottom: 2px;
}

.ai-chat__title_logo {
  height: 50px;
  padding-bottom: 3px;
}

.ai-chat__message_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
  font-size: 12px;
}

.ai-chat__message_text_loading {
  height: 30px;
}

.ai-chat__message_text_loading::after {
  animation: dots 2s linear infinite;
  content: '';
  font-size: 20px;
}

@keyframes dots {
0%,
20% {
  content: '.';
}
40% {
  content: '..';
}
60% {
  content: '...';
}
90%,
100% {
  content: '';
}
}

.ai-chat__history_container {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
  row-gap: 20px;
  height: 100%;
  overflow: auto;
}

.ai-chat__history_section_name {
  font-weight: 700;
  font-size: 12px;
  padding-left: 6px;
}

.ai-chat__history_chat_title {
  height: 35px;
  overflow: hidden;
  cursor: pointer;
  padding: 8px;

  &:hover {
    background-color: rgba(0, 175, 170, 0.4);
    color: white;
    border-radius: 10px;
  }
}

.ai-chat__history_active_chat {
  background-color: rgba(0, 175, 170, 0.8);
  color: white;
  border-radius: 10px;
}

.ai-chat__messages_container::-webkit-scrollbar,
.ai-chat__history_container::-webkit-scrollbar,
.ai-chat__prompt-container::-webkit-scrollbar, 
.ai-chat__prompt-textarea::-webkit-scrollbar {
  background-color: #f0f0f0;
  width: 8px;
  border: 3px solid #f0f0f0;
  border-radius: 10px;
  height: 50px;
}

.ai-chat__messages_container::-webkit-scrollbar-thumb,
.ai-chat__history_container::-webkit-scrollbar-thumb,
.ai-chat__prompt-container::-webkit-scrollbar-thumb,
.ai-chat__prompt-textarea::-webkit-scrollbar-thumb {
  width: 10px;
  border-radius: 10px;
  background-color: #c0c0c0;
  background-color: rgba(0, 175, 170, 0.3);
}


.ai-chat__messages_container::-webkit-scrollbar-thumb:hover,
.ai-chat__history_container::-webkit-scrollbar-thumb:hover,
.ai-chat__prompt-container::-webkit-scrollbar-thumb:hover,
.ai-chat__prompt-textarea::-webkit-scrollbar-thumb:hover {
  background-color: #c0c0c0;
  background-color: rgba(0, 175, 170, 0.6);
  cursor: pointer;
}

.ai-chat__selected_files_count {
  margin-left: 10px;
}

.ai-chat__help_icon {
  margin-left: 15px;
  cursor: pointer;
  color: black;
}

.ai-chat__help_icon:hover {
  color: #00AFAA;
}
