@import "../../../common/variables";

// Invite User Form - Modal Content
.standard-modal {
  background-color: $light-beige-bg;
  border-radius: 5px;
  &__title {
    font-family: $font-family-speziaserif;
    font-style: normal;
    font-weight: 400;
    font-size: 31px;
    line-height: 34px;
    letter-spacing: -0.05em;
    color: #0a0a0a;
  }
  .btn-primary {
    width: 116px;
    text-align: center;
  }
  .modal-buttons {
    &__container {
      display: flex;
      justify-content: center;
      button {
        margin: 0 10px;
        width: fit-content;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
      }
      .btn-cancel {
        background-color: transparent;
        color: $light-green;
      }
    }
  }

  .back-button {
    color: #00afaa;
    font-weight: 500;
    font-size: 16px;
  }

  .admin-checkbox {
    display: flex;
    align-items: center;
    svg {
      color: $light-green;
      margin-left: 8px;
    }
  }

  .upload {
    color: $light-green;
    // border-right: 1px solid #c8c5bd;
    padding-right: 15px;
    margin-right: 15px;
  }
  .upload-text {
    margin-left: 10px;
  }
  .dzu-inputLabel {
    color: #00afaa;
    font-weight: 400;
  }

  .uploaded-photo {
    border-radius: 100%;
    width: 179px;
    height: 179px;
  }

  .radio-select {
    width: 24px;
    height: 24px;
    margin-left: -15px;
  }

  .cancel-text {
    margin-left: 12px;
    margin-top: 5px;
  }

  .cancel-select.form-check-input:checked {
    background-color: #00afaa;
    border-color: #00afaa;
  }

  .dzu-dropzone {
    overflow-x: hidden;
    overflow-y: hidden;
    border: 2px solid #eceae4;
    border-radius: 0;
    min-height: 237px;
    margin-left: 0;
  }
  .dragdrop {
    text-align: center;
    height: 237px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    svg {
      color: $light-green;
    }
    p {
      margin-top: 11px;
      max-width: 300px;
      color: $light-green;
      font-size: 16px;
    }
  }
}

// Upload Roadblock
.upload-roadblock {
  font-size: 18px;
}

.folder-row {
  height: 60px;
  font-size: 16px;
  font-weight: 400;
  border-top: 1px solid #c8c5bd;
}

.folder-row-text {
  margin-left: 10px;
}

.add-folder-row {
  color: $light-green;
}

.right-arrow-icon {
  width: 12px;
  height: 18px;
  margin-left: auto;
}

.case-admins {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  font-weight: 600;
}

.close-case-name {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 10px;
}

.close-case-icon {
  width: 24px;
  height: 20px;
}

.remove-user-modal-text {
  font-size: 16px;
  font-weight: 400;
}

.select-search {
  width: 100% !important;
  height: 60px;
  font-family: inherit;
}

.select-search input:focus {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top: 1px solid $light-green !important;
  border-right: 1px solid $light-green !important;
  border-left: 1px solid $light-green !important;
}

.select-search input {
  height: 43px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: unset;
  box-shadow: unset;
  outline: unset;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-radius: 6px;
  font-family: inherit;
}

.select-search input:hover {
  border: unset;

  padding: -1px 41px 0 25px;
}

.select-search__value::after {
  content: url("../../../assets/downArrowIcon.png") !important;
  color: $light-green;
  border-right: unset !important;
  border-bottom: unset !important;
  transform: unset !important;
  right: 10px;
  height: 10px;
}

.select-search__value:focus-within::after {
  content: url("../../../assets/upArrowIcon.png") !important;
}

.select-search__option:hover {
  background: #76ccc7;
}

.select-search__option.is-selected {
  background: $light-green !important;
  color: #fff;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: transparent;
}

.select-search__options {
  padding-left: 0;
  margin-bottom: 0;
}

.select-search__row:not(:first-child) {
  border-top: unset !important;
}

.select-search__option {
  color: black;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.select-search__options {
  margin-top: -1px;
}

.select-search__select {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  position: unset !important;
  border-right: 1px solid $light-green !important;
  border-left: 1px solid $light-green !important;
  border-bottom: 1px solid $light-green !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: unset !important;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: white;
  color: black;
}
