@import '../../../common/variables';

.controls__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  margin-bottom: 10px;
  margin-top: 10px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    font-size: 16px;
  }
  @media screen and (max-width: 460px) {
    font-size: 14px;
  }
}

.controls__buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}

.controls__button {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $light-green;
  column-gap: 5px;
}

.controls__new-tag {
  margin-bottom: 20px;
  background-color: rgb(178, 62, 18);
  color: white;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 10%;
  font-size: 14px;
}

.controls__files-count {
  margin-right: 15px;
}

.controls__ai-logo {
  height: 22px;
  fill: $light-green;
}

.controls__separator {
  width: 1px;
  background-color: #c8c5bd;
  height: 18px;
  display: flex;
  align-self: center;
}

.controls {
  &__search-container {
    position: relative;
    min-width: 250px;
    @media screen and (max-width: 460px) {
      margin-top: 10px;
      input {
        font-size: 12px;
      }
      .form-control {
        padding: 0.375rem 0.75rem;
      }
    }
  }
}

.controls__search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}

.new-badge {
  background-color: #f55519 !important;
}

.controls__selected_files_count {
  padding-bottom: 9px;
  font-size: 21px;
  @media screen and (max-width: 768px) {
    font-size: 19px;
  }
  @media screen and (max-width: 460px) {
    font-size: 14px;
  }
}
