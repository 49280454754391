@import '../../common/variables';

.dashboard-multicase {
  /* height: 100%; */
  background: white;
  padding: 8px 20px;
  overflow-y: auto;

  &__table {
    width: 100%;
    th:first-child {
      width: 20%;
    }

    thead {
      th,
      td {
        padding: 10px;
      }
      tr {
        padding: 10px;
        background-color: $light-beige-bg;
        background: '#ECEAE4';
        color: 'black';
        font-weight: '400';
      }
    }

    tbody {
      tr {
        background-color: white;
        td {
          padding: 10px;
          background: none;
          border-bottom: 1px solid $light-beige-bg;
          &:first-child,
          &:last-child {
            color: $light-green;
          }
        }
      }
    }
  }
}

.litx-table {
  padding: 0 54px;
  @media screen and (max-width: 1025px) {
    padding: 0px;
    font-size: 14px;
  }

  &__title {
    font-family: $font-family-speziaserif;
    font-size: 46px;
    line-height: 52px;
    letter-spacing: -0.03em;
    color: #0b0f1b;

    @media screen and (max-width: 820px) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  &__selected-document {
    display: flex;
    align-items: center;
    color: $light-green;
  }

  &__tab-row {
    display: flex;
    max-width: 50%;
    align-items: center;

    @media screen and (max-width: 820px) {
      flex-direction: column;
      max-width: 100%;
    }
  }

  &__tab {
    color: red;
    margin-right: 45px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: $dark-green-bg;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.active {
      border-bottom: 4px solid $light-green;
    }

    @media screen and (max-width: 820px) {
      margin-right: 20px;
      font-size: 14px;
    }
  }

  .sort-icon {
    width: 20px;
    height: 12px;
    margin-left: 10px;
  }

  input[type='search'] {
    border: 1px solid #003732;
  }

  .search-svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
  }

  .search-spinner {
    margin-right: 10px;
    margin-top: 10px;
  }

  &__cases-count {
    font-size: 20px;
    font-weight: 300;
  }
}

.dashboard-multicase__table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  border: 2px solid rgb(236, 234, 228);
  table-layout: auto;

  thead th {
    background: #eceae4;
    color: black;
    font-weight: 400;
    height: 48px;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  tbody {
    display: block;
    height: 30rem;
    width: 100%;
    overflow-y: auto;
    @media screen and (max-width: 860px) {
      height: 100%;
    }
    tr {
      width: 100%;
      height: 75px;
      border-bottom: 1px solid rgb(236, 234, 228);
    }
  }

  th:first-child,
  td:first-child {
    width: 45% !important;
    padding-left: 10px !important;
  }

  td:not(:first-child),
  th:not(:first-child) {
    width: auto !important;
    text-align: center !important;
  }

  tbody::-webkit-scrollbar {
    background-color: #f0f0f0;
    width: 8px;
    border: 3px solid #f0f0f0;
    border-radius: 10px;
    height: 50px;
  }

  tbody::-webkit-scrollbar-thumb {
    width: 10px;
    border-radius: 10px;
    background-color: #c0c0c0;
    background-color: rgba(0, 175, 170, 0.3);
  }

  tbody::-webkit-scrollbar-thumb:hover {
    background-color: #c0c0c0;
    background-color: rgba(0, 175, 170, 0.6);
    cursor: pointer;
  }

  @media screen and (max-width: 860px) {
    th:first-child, td:first-child {
      width: auto !important;
      font-size: 12px !important;
    }
    
    td:not(:first-child),
    th:not(:first-child) {
      width: auto !important;
      font-size: 12px !important;
    }
    
    th:last-child,
    td:last-child {
      width: auto !important;
      font-size: 12px !important;
    }

    tbody tr {
      height: auto;
    }
  }
}

@media screen and (max-width: 450px) {
  .dashboard-multicase__table {
    th:nth-child(3), td:nth-child(3) {
      display: none;
    }
  }
}

@media screen and (max-width: 504px) {
  .dashboard-multicase__table {
    th:nth-child(4), td:nth-child(4) {
      display: none;
    }
  }
}

.cases-detail {
  width: 500px;

  .case-role-select {
    width: 150px;
    border: 1px solid black;
    border-radius: 6px;
  }
}

.cases-search {
  width: 400px;
}

.dashboard-multicase__pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 20px;
  column-gap: 10px;
  font-size: 20px;
}

.dashboard-multicase__pagination-arrow {
  cursor: pointer;
}

.dashboard-multicase__pagination-arrow-hidden {
  visibility: hidden;
}
