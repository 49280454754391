@import "../../common/variables";

body, html {
  height: 100%;
  margin: 0;
}

.auth__container {
  display: flex;
  height: 100%;
  width: 100%;
  height: 100vh;
  background: $dark-green-bg;
}

.login {
  background: $dark-green-bg;
  display: flex;
  flex: 0 1 auto;
  height: 100%;

  &__sidebar {
    background: $light-beige-bg;
    padding: 48px;
    width: 90%;
    height: 100%;
    @media screen and (max-width: 376px) {
      padding: 10px;
    }
  }

  @media screen and (max-width: 800px) {
    &__sidebar {
      width: 100%;
    }
  }

  form {
    label {
      margin-top: 0.5rem;
      margin-bottom: 0px;
    }
    input {
      margin-bottom: 0.5rem;
      border-radius: 4px;
      border: 1px solid $light-green;
    }
  }


}

.content_right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  height: auto;
  margin-bottom: 0.7rem;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
  }

  img {
    max-width: 80%;
    min-width: 400px;
    align-self: flex-start;
  }

  &__copyright {
    font-weight: 400;
    font-size: 12px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #ffffff;
  }

  &__content-title {
    font-family: $font-family-speziaserif;
    font-style: normal;
    font-weight: 400;
    font-size: 4vw;
    line-height: 1.2;
    color: #ffffff;
    width: 50%;
  }
}

@media screen and (max-width: 800px) {
  .content_right {
    display: none;
  }
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 90%;

  &__title {
    margin-top: 4rem;
    margin-bottom: 1rem;
    font-weight: 400;
    font-size: clamp(30px, 3vw, 48px);
    line-height: 1.2;
    letter-spacing: -0.03em;
    color: #000000;
    text-align: left;
  }

  @media screen and (max-width: 400px) {
    &__title {
      margin-top: 1rem;
    }
  }

  &__assistance-links {
    display: flex;
    flex-direction: column;
    p, a {
      margin-top: 1rem;
    }
  }

  input {
    width: 100%;
  }
}

// TODO: replace with link
.password-reset-link {
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: $light-green;
}
