@import '../../../common/variables';

.table-file-icon, .table-file-fill-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.table-file-fill-icon {
  color: $light_green;
}

.table-X-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  color: #f44336;
}

.table-file-icon-ai__container {
  position: relative;
  overflow: visible;
}

.table-file-icon-ai__tooltip {
  position: absolute;
  z-index: 1000;
  background-color: $light_green;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  width: 190px;
  font-size: 14px;
  top: 100%;
  transform: translateY(-60%);
}
