@import "variables";

// Bootstrap Overrides

body {
  background-color: $light-beige-bg;
}

.container-xl {
  max-width: 1384px !important;
}

/* input {
  border: 1px solid transparent !important;
} */

*:focus-visible:not(.select-search__input) {
  border: 1px solid $light-green !important;
  box-shadow: unset !important;
}

.form-control {
  border-radius: 5px;
}

.form-check-input.is-valid {
  border-color: unset !important;
}

textarea {
  resize: none !important;
}

.btn {
  font-size: 16px !important;
  line-height: 18px !important;
  font-weight: 700 !important;
  letter-spacing: 0.02em !important;
  font-feature-settings: "tnum" on, "lnum" on;
  font-family: $font-family-suisse !important;
}

.btn-primary {
  color: $light-beige-bg;
  background-color: $dark-green-bg;
  width: max-content;
  border-radius: 23px;
  border: none;

  &:hover {
    background-color: #005951;
  }
}
.btn-white {
  background-color: white;
}
.btn-secondary {
  background-color: $light-green;
}
.btn-light {
  color: $light-green;
  background-color: transparent;
}
.btn {
  width: max-content;
  padding: 10px 20px;
  border-radius: 23px;
  border: none;
}
.form-select,
.form-control {
  border: none;
  padding: 0.575rem 0.75rem;
}

.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%2300AFAA%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e") !important;
}

.form-control:disabled,
.form-select:disabled {
  background-color: #f2f1ed;
}

.action-link {
  color: $light-green;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

h1 {
  font-family: $font-family-speziaserif;
  font-style: normal;
  font-size: 66px;
  letter-spacing: -0.03em;
  color: #0b0f1b;
  line-height: 66px;
}

h2 {
  font-family: $font-family-speziaserif;
  font-style: normal;
  font-size: 54px;
  letter-spacing: -0.03em;
  color: #0b0f1b;
}

h3 {
  font-family: $font-family-speziaserif;
  font-style: normal;
  font-size: 42px;
  letter-spacing: -0.03em;
  color: #0b0f1b;
}

h4 {
  font-family: $font-family-speziaserif;
  font-style: normal;
  font-size: 36px;
  letter-spacing: -0.03em;
  color: #0b0f1b;
}

h5 {
  font-family: $font-family-speziaserif;
  font-style: normal;
  font-size: 26px;
  letter-spacing: -0.04em;
  color: #0b0f1b;
}

h6 {
  font-family: $font-family-speziaserif;
  font-style: normal;
  font-size: 20px;
  letter-spacing: -0.04em;
  color: #0b0f1b;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
  background-color: unset !important;
  border-color: #494949 !important;
  width: 1.2em !important;
  height: 1.2em !important;
}

.form-check-input:checked[type="checkbox"] {
  background-color: #00afaa !important;
  border-color: #00afaa !important;
}

.form-check-label {
  margin-top: 2px;
}

.info-icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

// Element styles
.link {
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: $light-green;
  cursor: pointer;
}
